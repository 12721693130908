.LoginScreenContainer {
  display: flex;
  flex-direction: row;
  background-image: var(--login-background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.LoginHeader {
  background-color: var(--main-color);
  height: 120pt;
}

.LoginBody {
  margin-top: 100pt;
}

.LoginContainer {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.SplashScreenContainer {
  width: 'auto';
}

.LoginTitle {
  font-size: 18pt;
  font-weight: bold;
  margin-left: 20pt;
  margin-right: 20pt;
}
