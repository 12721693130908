@import '../../Datas/ProjectDatas/ProjectValues.scss';

.adminSecondToolBar {
  padding: 20pt;
}

.newElementContainer {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.newElementColumn {
  height: 100%;
  flex: 0 0 50%;
  max-width: 50%;
}

.newElementProperty {
  padding: 15pt;
  height: 100%;
}

h1 {
  color: var(--main-color);
}

h3 {
  font-weight: 500;
}

.sidebarLogo {
  height: 1em;
  width: auto;
}

.researchButton {
  position: absolute;
  height: 100%;
}

.CheckboxRecurantNotification {
  margin-bottom: auto;
}

.textWithIcon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.normalRow {
  background-color: white;
}

.selectedRow {
  background-color: #e1f5fe;
}

.InputElement {
  margin: 18pt;
  margin-left: 22pt;
  margin-right: 22pt;
}
